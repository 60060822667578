import React, { useEffect, useState, useMemo, useCallback } from "react";
import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";

import {
    Box,
    Checkbox,
    Typography,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    InputLabel,
    Card,
    CardContent,
    Avatar,
    Menu,
    IconButton,
    Icon,
    CircularProgress,
    Tooltip,
    Grow,
    MenuList,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
} from "@material-ui/core";
import {
    Call,
    PhoneCallback,
    Email,
    Edit,
    Help,
    ExpandLess,
    ExpandMore,
    Undo,
    FiberManualRecord as MuiDot,
    Replay,
    NewReleases,
    Assignment,
    Info,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import avatarStyles from "../theme/avatars";
import { setSnackAction } from "../redux/actions/snackActions";
import { setFullScreenEmail, setRaiseCaseRequestId, setSeekAdviceMiscActivityId } from "../redux/actions/userActions";
import ReassignTaskDialog from "../components/ReassignTaskDialog";
import { format } from "date-fns";
import twilioService from "../services/twilioService";
import TwilioSummaryModal from "../components/TwilioSummaryModal";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import useAdviceTypesForUser from "../hooks/queries/useAdviceTypesForUser";
import clsx from "clsx";

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    miniLabel: {
        fontSize: "0.7em",
        color: "#666",
    },
    importantRow: {
        backgroundColor: "#FFCCCB",
    },
    boxFill: {
        width: "100%",
    },
    menuListNoPad: {
        padding: "0px",
    },
    headlineBox: {
        backgroundColor: "lightgrey",
        width: "140px",
        height: "60px",
        alignItems: "center",
        justifyContent: "center",
        padding: "1px",
    },
    userAv: {
        marginLeft: "8px",
    },
    taskIcon: {
        color: "#757575",
        marginRight: "2px",
    },
    expandIcon: {
        color: "#757575",
        marginLeft: "8px",
    },
    dot: {
        height: "14px",
        width: "14px",
        marginLeft: "8px",
        color: (props) => (props.available ? "#31AA71" : "#999999"),
    },
}));

function DotAvail({ available }) {
    const classes = formStyles({ available });
    return <MuiDot className={classes.dot}></MuiDot>;
}

const TwilioTasks = ({ userState, setSnack, setFullScreenEmail }) => {

    const { data: adviceTypes } = useAdviceTypes();
    const { data: userAdviceTypes } = useAdviceTypesForUser(userState.userProfile.userId);
    const [adviceTypeId, setAdviceTypeId] = useState("");

    const allowedAdviceTypes = useMemo(() => {
        if (!adviceTypes || !userAdviceTypes)
            return [];

        return adviceTypes.filter(at => userAdviceTypes.includes(at.id))
    }, [adviceTypes, userAdviceTypes]);

    const formClasses = formStyles();
    const avatarClasses = avatarStyles();
    const filteredAdvisers = useFilteredAdvisers();
    const [tasks, setTasks] = useState([]);
    const [teams, setTeams] = useState([]);
    const [workers, setWorkers] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [queueOpen, setQueueOpen] = useState(false);
    const [teamOpen, setTeamOpen] = useState(false);
    const [adviserOpen, setAdviserOpen] = useState(false);
    const [adviserLoading, setAdviserLoading] = useState(false);
    const [menuTask, setMenuTask] = useState(null);
    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [tasksLoading, setTasksLoading] = useState(false);
    const [queues, setQueues] = useState({});
    const [userId, setUserId] = useState("");
    const [assigning, setAssigning] = useState(false);
    const [teamId, setTeamId] = useState("");
    const [taskTypeText, setTaskTypeText] = useState("");
    const [statusText, setStatusText] = useState("");
    const [importanceChecked, setImportanceChecked] = useState(false);
    const [page, setPage] = useState(0);

    const rowsPerPage = 50;

    useEffect(() => {
        setPage(0);
    }, [statusText, importanceChecked, taskTypeText, userId, teamId, adviceTypeId]);

    const dispatch = useDispatch();

    const adviceTypeName = (id) => id ? adviceTypes.find(at => at.id === id).name : "Not set";

    // for assigning task to adviser
    const emptyOptions = {
        userId: null,
        queueName: null,
        teamId: null,
    };
    const [assignTaskOpen, setAssignTaskOpen] = useState(false);
    const [taskOptions, setTaskOptions] = useState(emptyOptions);
    const [twilioSummary, setTwilioSummary] = useState(null);

    const allUserIds = useMemo(() => {
        let collections = tasks.map(t => [t.userId || 0, t.attributes.adviser_id || 0, ...(t.attributes.user_ids || []), ...(t.attributes.expanded_user_ids || [])]);
        let flattened = collections.flat(1);
        let unique = [...new Set(flattened)];
        return unique;
    }, [tasks]);


    const userList = useMemo(() => {
        return filteredAdvisers
            .filter((u) => allUserIds.includes(u.userId))
            .sort((a, b) => (a.name > b.name ? 1 : -1));
    }, [allUserIds, filteredAdvisers]);

    const filteredTasks = useMemo(() => {
        if (!userAdviceTypes)
            return [];

        let filtered = tasks;

        filtered = filtered.filter(t => !t.attributes.advice_type_id || userAdviceTypes.includes(t.attributes.advice_type_id));

        if (userId)
            filtered = filtered.filter(
                t => {
                    let userIds = [t.userId || 0, t.attributes.adviser_id || 0, ...(t.attributes.user_ids || []), ...(t.attributes.expanded_user_ids || [])];
                    return userIds.includes(userId)
                }
            );

        if (teamId) {
            const selectedTeam = teams.find((x) => x.teamId === teamId);
            const { userIds: teamUsersIds } = selectedTeam;
            filtered = filtered.filter((t) =>
                teamUsersIds.some(
                    (uid) =>
                        t.attributes.user_ids?.includes(uid) ||
                        t.attributes?.adviser_id === uid ||
                        t.userId === uid
                )
            );
        }

        if (adviceTypeId !== "")
            filtered = adviceTypeId === 0
                ? filtered.filter(t => !t.attributes.advice_type_id)
                : filtered.filter(t => t.attributes.advice_type_id === adviceTypeId);

        if (statusText)
            filtered = filtered.filter(t => t.status === statusText);

        if (taskTypeText)
            filtered = filtered.filter(t => t.taskChannel === taskTypeText);

        if (importanceChecked)
            filtered = filtered.filter(t => t.attributes.important);

        return filtered;
    }, [tasks, userId, teamId, adviceTypeId, statusText, taskTypeText, importanceChecked, userAdviceTypes, teams]);

    const displayedTasks = useMemo(() => {

        let displayed = filteredTasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return displayed;
    }, [filteredTasks, page]);

    const handleOpenMenu = (target, task) => {
        setAnchorEl(target);
        setMenuTask(task);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuTask(null);
        setAdviserOpen(false);
        setAdviserLoading(false);
        setQueueOpen(false);
    };

    const getWorkers = useCallback(async () => {
        try {
            let response = await twilioService.getWorkers();
            setWorkers(response.data);
        } catch (error) {
            setSnack("Couldn't retrieve workers. If problem persists contact a member of IT support", "error");
            console.error(error);
        }
    }, [setSnack]);

    const handleAdviserOpen = useCallback(async () => {
        if (adviserOpen) {
            setAdviserOpen(false);
            return;
        }
        setAdviserOpen(true);
        setAdviserLoading(true);
        getWorkers();
        setAdviserLoading(false);
    }, [adviserOpen, getWorkers]);

    const handleAssignTask = async (options) => {
        if (!menuTask) return;

        setAssigning(true);
        try {
            await twilioService.reassignTask(menuTask.taskSid, options);
            setSnack("Successfully reassigned task!", "success");
        } catch (error) {
            setSnack("Sorry, looks like this task no longer exists", "error");
            console.error(error);
        }
        setAssigning(false);
        handleCloseMenu();
        getTasks();
    };

    const getTasks = useCallback(async () => {
        setTasksLoading(true);
        try {
            let response = await twilioService.getTasks();
            setTasks(response.data);
            setLastRefreshed(new Date());
        } catch (error) {
            setSnack("Couldn't retrieve tasks. If problem persists contact a member of IT support", "error");
            console.error(error);
        }

        setTasksLoading(false);
    }, [setSnack]);

    const handleClickAssignTask = (options) => {
        setTaskOptions(options);
        setAssignTaskOpen(true);
    };

    const handleCloseAssignTask = () => {
        setAssignTaskOpen(false);
        setTaskOptions(emptyOptions);
    };

    const handleOpenTwilioSummary = (task) => {
        const formatSummaryData = {
            caseId: task?.attributes?.case_id,
            caseDescription: task?.attributes?.case_description,
            actionCategory: task?.attributes?.action_category,
            actionType: task?.attributes?.action_type,
            actionSummary: task?.attributes?.action_summary,
            details: task?.attributes?.action_details,
        };
        setTwilioSummary(formatSummaryData);
    };

    const handleCloseTwilioSummary = () => setTwilioSummary(null);

    useEffect(() => {
        const getQueues = async () => {
            try {
                let response = await twilioService.getTaskQueues();
                setQueues(response.data);
            } catch (error) {
                setSnack("Couldn't retrieve TaskQueues. If problem persists contact a member of IT support", "error");
                console.error(error);
            }
        };

        const getTeams = async () => {
            try {
                let data = await twilioService.getTeams();
                setTeams(data);
            } catch (error) {
                setSnack("Couldn't retrieve Teams. If problem persists contact a member of IT support", "error");
                console.error(error);
            }
        };

        getQueues();
        getTasks();
        getTeams();
        getWorkers();
    }, [getTasks, getWorkers, setSnack]);

    const getTaskIcon = (taskChannel) => {
        switch (taskChannel) {
            case "voice":
                return <Call />;
            case "email":
                return <Email />;
            case "callback":
                return <PhoneCallback />;
            case "reminder":
                return <Assignment />;
            default:
                return <Help />;
        }
    };

    const openEmail = async (t) => {
        try {
            let response = await twilioService.getTaskEmail(t.taskSid);
            if (response.data) {
                setFullScreenEmail(
                    {
                        ...response.data,
                        partner: t.attributes?.partner || null,
                    },
                    false,
                    true
                );
            } else {
                setSnack("Couldn't retrieve the email. Please try again. If problem persists contact a member of IT support", "error");
            }
        } catch (error) {
            setSnack("Couldn't retrieve the email. Please try again. If problem persists contact a member of IT support", "error");
            console.error(error);
        }
    };

    const menuItems = useMemo(() => {
        if (!menuTask)
            return [];

        var items = [];

        items.push(
            <MenuItem
                onClick={() => setQueueOpen(!queueOpen)}
                key="assign_queue"
            >
                <Box
                    className={formClasses.boxFill}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Typography variant="subtitle2">Assign to queue</Typography>
                    <Icon className={formClasses.expandIcon}>
                        {queueOpen ? <ExpandLess /> : <ExpandMore />}
                    </Icon>
                </Box>
            </MenuItem>
        );

        if (queueOpen)
            items.push(
                <Grow in={queueOpen} key="queues">
                    <MenuList className={formClasses.menuListNoPad}>
                        {Object.keys(queues).map((q) => (
                            <MenuItem
                                onClick={() => handleClickAssignTask({ queueName: q })}
                                key={queues[q]}
                            >
                                {" "}
                                {q}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Grow>
            );

        items.push(
            <MenuItem onClick={() => setTeamOpen(!teamOpen)} key="assign_team">
                <Box
                    className={formClasses.boxFill}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Typography variant="subtitle2">Assign to team</Typography>
                    <Icon className={formClasses.expandIcon}>
                        {teamOpen ? <ExpandLess /> : <ExpandMore />}
                    </Icon>
                </Box>
            </MenuItem>
        );

        if (teamOpen)
            items.push(
                <Grow in={teamOpen} key="teams">
                    <MenuList className={formClasses.menuListNoPad}>
                        {teams.map((t) => (
                            <MenuItem
                                onClick={() => handleClickAssignTask({ teamId: t.teamId })}
                                key={t.teamId}
                            >
                                {" "}
                                {t.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Grow>
            );

        items.push(
            <MenuItem onClick={handleAdviserOpen} key="assign_adviser">
                <Box
                    className={formClasses.boxFill}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Typography variant="subtitle2">Assign to adviser</Typography>
                    <Icon className={formClasses.expandIcon}>
                        {adviserOpen ? <ExpandLess /> : <ExpandMore />}
                    </Icon>
                </Box>
            </MenuItem>
        );

        if (adviserOpen)
            items.push(
                <MenuList className={formClasses.menuListNoPad} key="advisers">
                    {adviserLoading ? (
                        <MenuItem>
                            <Box
                                className={formClasses.boxFill}
                                display="flex"
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Box>
                        </MenuItem>
                    ) : (
                        userList.map((u) => (
                            <MenuItem
                                key={u.userId}
                                onClick={() => handleClickAssignTask({ userId: u.userId })}
                            >
                                <Box display="flex" alignItems="center">
                                    <Avatar alt={u.name} src={u.photo} />
                                    <Typography className={formClasses.userAv}>
                                        {u.name}
                                    </Typography>
                                    <DotAvail
                                        available={workers[u.email.toLowerCase()] === true}
                                    />
                                </Box>
                            </MenuItem>
                        ))
                    )}
                </MenuList>
            );

        if (menuTask.status === "assigned") {
            items.push(
                <MenuItem
                    onClick={() => handleClickAssignTask({})}
                    key="assign_routing"
                >
                    <Box
                        className={formClasses.boxFill}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <Typography variant="subtitle2">
                            Re-queue task
                        </Typography>
                        <Icon className={formClasses.expandIcon}>
                            <Undo />
                        </Icon>
                    </Box>
                </MenuItem>
            );
        }

        return items;
    }, [menuTask, formClasses.boxFill, formClasses.expandIcon, formClasses.menuListNoPad, formClasses.userAv, queueOpen, queues, teamOpen, teams, handleAdviserOpen, adviserOpen, adviserLoading, userList, workers]);

    const UserAvatar = ({ id, className }) => {
        const user = userState.users[id] || { name: "???" };

        return (
            <Tooltip title={user.name}>
                <Avatar
                    alt={user.name}
                    src={user.photo}
                    className={className}
                />
            </Tooltip>
        );
    };

    const QueuedFor = ({ task }) => {
        const { attributes } = task;

        if (!attributes)
            return null;

        const hasAdviser = !!attributes.adviser_id;
        const hasUsers = attributes.user_ids && attributes.user_ids.length > 0;
        const hasExtras = attributes.expanded_user_ids && attributes.expanded_user_ids.length > 0;
        const hasQueue = !!attributes.queue;

        return (
            <Box display="flex" flexDirection="column">
                {
                    hasAdviser &&
                    <Typography className={formClasses.miniLabel}>
                        Adviser
                    </Typography>
                }
                {
                    hasAdviser &&
                    <UserAvatar
                        id={attributes.adviser_id}
                        className={avatarClasses.small}
                    />
                }
                {
                    hasUsers &&
                    <Typography className={formClasses.miniLabel}>Team</Typography>
                }
                {
                    hasUsers &&
                    <Box display="flex" flexDirection="row">
                        {attributes.user_ids.map((id) => (
                            <UserAvatar
                                id={id}
                                key={id}
                                className={avatarClasses.small}
                            />
                        ))}
                    </Box>
                }
                {
                    hasExtras &&
                    <Typography className={formClasses.miniLabel}>
                        Extras
                    </Typography>
                }
                {
                    hasExtras &&
                    <Box>
                        {attributes.expanded_user_ids.map((id) => (
                            <UserAvatar
                                id={id}
                                key={id}
                                className={avatarClasses.small}
                            />
                        ))}
                    </Box>
                }
                {
                    hasQueue &&
                    <Typography className={formClasses.miniLabel}>Queue</Typography>
                }
                {
                    hasQueue &&
                    <Typography>{attributes.queue}</Typography>
                }
            </Box>
        );
    };

    const uniqueStatus = [...new Set(filteredTasks.map((item) => item.status))];
    const uniqueTaskType = [
        ...new Set(filteredTasks.map((item) => item.taskChannel)),
    ];

    const addHours = (date, hours) => {
        return date.setHours(date.getHours() + hours);
    };

    const handleImportanceChange = (event) => {
        setImportanceChecked(event.target.checked);
    };

    const previewSeekAdvice = (t) => {
        if (!!t.attributes.raise_case_request_id)
            dispatch(setRaiseCaseRequestId(t.attributes.raise_case_request_id));

        else if (!!t.attributes.case_activity_misc_id)
            dispatch(setSeekAdviceMiscActivityId(t.attributes.case_activity_misc_id));
    }
    return (
        <React.Fragment>
            <Helmet title="Twilio Tasks" />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h3">Twilio Tasks</Typography>
                <Box display="flex" className={formClasses.headlineBox}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mx={3}
                    >
                        <Box textAlign="center" height="16px" mb={2}>
                            Unassigned
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={2}
                            height="20px"
                            width="60px"
                        >
                            <div>
                                <Typography variant="h3">{filteredTasks.filter(t => t.status !== "assigned").length}</Typography>
                            </div>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" className={formClasses.headlineBox}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mx={3}
                    >
                        <Box textAlign="center" height="16px" mb={2}>Assigned</Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={2}
                            height="20px"
                            width="60px"
                        >
                            <div>
                                <Typography variant="h3">{filteredTasks.filter(t => t.status === "assigned").length}</Typography>
                            </div>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" className={formClasses.headlineBox}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mx={3}
                    >
                        <Box textAlign="center" height="16px" mb={2}>Overdue</Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={2}
                            height="20px"
                            width="60px"
                        >
                            <div>
                                <Typography variant="h3">{filteredTasks.filter(t => t.attributes.task_due <= new Date().toISOString()).length}</Typography>
                            </div>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" className={formClasses.headlineBox}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mx={3}
                    >
                        <Box textAlign="center" height="16px" mb={2}>
                            Due in next hour
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={2}
                            height="20px"
                            width="60px"
                        >
                            <div>
                                <Typography variant="h3">
                                    {
                                        filteredTasks.filter(t =>
                                            t.attributes.task_due >= new Date().toISOString() &&
                                            t.attributes.task_due <= new Date(addHours(new Date(), 1)).toISOString()
                                        ).length
                                    }
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" className={formClasses.headlineBox}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mx={1}
                    >
                        <Box textAlign="center" height="16px" mb={2}>
                            Due Today
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={2}
                            height="20px"
                            width="60px"
                        >
                            <div>
                                <Typography variant="h3">{filteredTasks.filter(t => t.attributes.task_due.date === new Date().date).length}</Typography>
                            </div>
                        </Box>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    width="140px"
                    height="60px"
                    alignItems="center"
                    justifyContent="center"
                    padding="1px"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mx={1}
                    >
                        <Box textAlign="center" height="30px" mb={2}>
                            Last Updated
                            <IconButton onClick={getTasks}>
                                <Replay />
                            </IconButton>
                        </Box>
                        <Box textAlign="center" height="30px">
                            <Typography>
                                {format(
                                    new Date(lastRefreshed),
                                    "dd/MM/yyyy HH:mm:ss"
                                )}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={2}
                            height="30px"
                            width="60px"
                        >
                            <FormControl className={formClasses.formControl}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={importanceChecked}
                                            onChange={handleImportanceChange}
                                            name="checked"
                                            color="primary"
                                        />
                                    }
                                    label="Show Important"
                                />
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display="flex">
                <FormControl className={formClasses.formControl}>
                    <InputLabel>User</InputLabel>
                    <Select
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                    >
                        <MenuItem value="">All</MenuItem>
                        {userList.map((u) => (
                            <MenuItem key={u.userId} value={u.userId}>
                                <Box display="flex" alignItems="center">
                                    <Avatar
                                        className={clsx(formClasses.userAv, avatarClasses.small)}
                                        alt={u.name}
                                        src={u.photo}
                                    />
                                    {u.name}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={formClasses.formControl}>
                    <InputLabel>Team</InputLabel>
                    <Select
                        value={teamId}
                        onChange={(e) => setTeamId(e.target.value)}
                    >
                        <MenuItem value="">All</MenuItem>
                        {teams.map((team) => (
                            <MenuItem key={team.teamId} value={team.teamId}>
                                {team.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={formClasses.formControl}>
                    <InputLabel>Task Type</InputLabel>
                    <Select
                        value={taskTypeText}
                        onChange={(e) => setTaskTypeText(e.target.value)}
                    >
                        <MenuItem value="">All</MenuItem>
                        {uniqueTaskType.map((tasktype, index) => (
                            <MenuItem key={index} value={tasktype}>{tasktype.charAt(0).toUpperCase()}{tasktype.slice(1)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {
                    allowedAdviceTypes.length > 1 &&
                    <FormControl className={formClasses.formControl}>
                        <InputLabel>Advice Type</InputLabel>
                        <Select
                            onChange={(e) => setAdviceTypeId(e.target.value)}
                            value={adviceTypeId}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value={0}>Not set</MenuItem>
                            {
                                allowedAdviceTypes.map((at) => <MenuItem key={at.id} value={at.id}>{at.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                }
                <FormControl className={formClasses.formControl}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={statusText}
                        onChange={(e) => setStatusText(e.target.value)}
                    >
                        <MenuItem value="">All</MenuItem>
                        {uniqueStatus.map((status, index) => <MenuItem key={index} value={status}>{status.charAt(0).toUpperCase()}{status.slice(1)}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
            <Card mb={6}>
                <CardContent>
                    {tasksLoading ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            className={formClasses.boxFill}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        count={filteredTasks.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(e, newPage) => setPage(newPage)}
                                    />
                                </TableRow>
                                <TableRow>
                                    <TableCell>Logged / Received</TableCell>
                                    <TableCell>Due By</TableCell>
                                    <TableCell>Queue</TableCell>
                                    <TableCell>Team/Adviser</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Advice Type</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell>Details</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayedTasks.map((t) => (
                                    <TableRow
                                        key={t.taskSid}
                                        hover
                                        className={t.attributes.important ? formClasses.importantRow : null}
                                    >
                                        <TableCell>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Icon className={formClasses.taskIcon}>
                                                    {" "}
                                                    {getTaskIcon(t.taskChannel)}
                                                    {t.attributes.important && <NewReleases />}
                                                </Icon>
                                                {format(new Date(t.dateCreated), "dd/MM/yy HH:mm")}
                                            </div>{" "}
                                        </TableCell>
                                        <TableCell>{t.attributes && format(new Date(t.attributes.task_due), "dd/MM/yy HH:mm")} </TableCell>
                                        <TableCell>{t.taskQueue}</TableCell>
                                        <TableCell><QueuedFor task={t} /></TableCell>
                                        <TableCell>
                                            {t.status === "assigned" ? (
                                                <UserAvatar
                                                    id={t.userId}
                                                    className={avatarClasses.small}
                                                />
                                            ) : (
                                                t.status.charAt(0).toUpperCase() + t.status.slice(1)
                                            )}
                                        </TableCell>
                                        <TableCell>{adviceTypeName(t.attributes.advice_type_id)}</TableCell>
                                        <TableCell>
                                            {t.taskChannel === "email" || t.taskChannel === "seek-advice" ? (
                                                t.attributes.from
                                            ) : t.taskChannel === "reminder" ? (
                                                "N/A"
                                            ) : (
                                                <React.Fragment>
                                                    {t.attributes.contact_account_name}
                                                    {t.attributes.contact_account_name && <br />}
                                                    {t.attributes.contact_name || "Unknown contact"}{" "}-{" "}
                                                    {t.attributes.callback_number}
                                                </React.Fragment>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {(t.taskChannel === "email" || t.taskChannel === "seek-advice") ? (
                                                <IconButton onClick={() => t.taskChannel === "email" ? openEmail(t) : previewSeekAdvice(t)}>
                                                    <Email />
                                                </IconButton>
                                            ) : t.taskChannel === "reminder" ? (
                                                <IconButton onClick={() => handleOpenTwilioSummary(t)}>
                                                    <Info />
                                                </IconButton>
                                            ) : (
                                                <div>{t.attributes && t.attributes.callback_note}</div>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={(e) => handleOpenMenu(e.currentTarget, t)}>
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </CardContent>
            </Card>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {assigning ? (
                    <Box
                        className={formClasses.boxFill}
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    menuItems
                )}
            </Menu>
            <ReassignTaskDialog
                open={assignTaskOpen}
                onClose={handleCloseAssignTask}
                options={taskOptions}
                handleAssignTask={handleAssignTask}
                teams={teams}
            />
            <TwilioSummaryModal
                open={!!twilioSummary}
                onClose={handleCloseTwilioSummary}
                summary={twilioSummary}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({ userState: state.userReducer });

const mapDispatchToProps = (dispatch) => ({
    setSnack: (message, severity) => dispatch(setSnackAction(message, severity)),
    setFullScreenEmail: email => dispatch(setFullScreenEmail(email, false, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwilioTasks);